<section class="login-section">
    <div class="left-img-section">
        <img src="./../../../assets/images/signin-signup/login-flow-main-img.png" alt="">
    </div>
    <div class="right-login-section">
        <div class="signin-model">
            <div class="flow-heading">Welcome Back</div>
                <div class="flow-sub-heading">Please enter your login credentials</div>
                <div class="signin-form">
                    <div class="field">
                        <div class="label">Email</div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput  autocomplete="off" placeholder="Enter your Mail Id" [(ngModel)]="email"/>
                        </mat-form-field>
                    </div>
                    <div class="field">
                        <div class="label">Password</div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter your password" [(ngModel)]="password"
                            (keyup.enter)="login()"/>
                            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="recovery cursor-pointer">Forgot Password?</div> -->
                <div class="field-btn">
                    <button class="signin-btn" (click)="login()">
                        <span *ngIf="!signInLoader">Sign In</span>
                        <span *ngIf="signInLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
                    </button>
                </div>
        </div>
    </div>
</section>