import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openPopup(componentName, height, width, dataModel) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true
    });
  }

  loginStaff(data){
    return this.http.post(
      environment.baseUrl + `/login/`,data
    )
  }
  resetPassword(data){
    return this.http.post(
      environment.baseUrl+`/login/password`,data
    )
  }
}
