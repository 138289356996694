import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminConsoleComponent } from './all-components/admin-console/admin-console.component';
import { AuthenticationComponent } from './all-components/authentication/authentication.component';
import { AuthenticationGuard } from './all-components/global-service-files/authentication.guard';
import { LoaderComponent } from './all-components/shared-components/loader/loader.component';

const routes: Routes = [
  {
    path:'',
    redirectTo:'authentication',
    pathMatch:'full'
  },
  {
    path:'authentication',
    component:AuthenticationComponent
  },
  {
    path:'loader',
    component:LoaderComponent
  },
  {
    path:'startup',
    component:AdminConsoleComponent,
    children:[
      {
        path:'admin-portal',
        loadChildren:() => 
          import(
            './all-components/admin-module/admin-module.module'
          ).then(
            (module) => {return module.AdminModuleModule}),
            canActivate:[AuthenticationGuard]
        }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
