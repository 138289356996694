import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../../global-service-files/db.service';

@Component({
  selector: 'startup-admin-web-service-panel',
  templateUrl: './web-service-panel.component.html',
  styleUrls: ['./web-service-panel.component.scss']
})
export class WebServicePanelComponent implements OnInit {

  @Output() panelValueSelection = new EventEmitter<any>();
  @Output() masterPanelClose = new EventEmitter<any>();
  srcWidth : number;
  srcHeight : number;

  mainPanel: any =[
    {
      name:'contact_us',
      status:false,
      panelUrlCheck:'webservices',
      routingUrl:'contactus'
    },
    {
      name:'news_alert',
      status:false,
      panelUrlCheck:'webservices',
      routingUrl:'newsalert'
    },
    {
      name:'deal_feeder',
      status:false,
      panelUrlCheck:'webservices',
      routingUrl:'dealfeeder'
    }
  ];

  constructor(
    private _router : Router,
    private _dbService : DbService
  ) {  
    this.getScreenSize();
    // this.checkForRoles();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    let Url = this._router.url;
    let parts = Url.split('/');
    this.mainPanel.forEach(element => {
      if(element.routingUrl === parts[4]){
        element.status = true;
      }
    });
  }
  // checkForRoles(){
  //   if(this._dbService.getStaffRole() === 'STAFF'){
  //     this.mainPanel.forEach((element,i) => {
  //       if(element.name === 'staff'){
  //         this.mainPanel.splice(i,1)
  //       }
  //     });
  //   }
  //   // else if(){
  //   //   this.mainPanel.forEach((element,i) => {
  //   //     if(element.name === 'staff')
  //   //   });
  //   // }
  // }

  openList(item,status){
    this.panelValueSelection.emit({value:'webservices'})
    this.mainPanel.forEach(element => {
      if(element?.name.split('_').join('') === item?.name.split('_').join('')){
        element.status = true;
        this._router.navigate([`/startup/admin-portal/webservices/${element.routingUrl}/list-${element.routingUrl}`],{queryParams:{pageNo:1}});
      }
      else{
        element.status = false
      }
    });
  }
  logout(){
    // this._dbService.removeAll();
    // localStorage.clear();
    // this._router.navigate([''])
    window.location.reload();
  }
  close(){
    this.masterPanelClose.emit(false)
  }

  goBack(){
    let url = this._dbService.getPrevUrl();
    let pathName = new URL(url).pathname;
    this._router.navigate([`${pathName}`],{queryParams:{pageNo:1}});
    this._dbService.setPrevUrl(window.location.href);
  }
  gotoHome(){
    this._router.navigate([`/startup/admin-portal/main/talenthire/list-talenthire`],{queryParams:{pageNo:1}})
  }
}
