<section id="admin-console">
    <div class="toggle-section">
        <div class="side-panel" [ngClass]="expand ? 'expand' : 'close'" *ngIf="panelName === 'main' && srcWidth > 475">
            <startup-admin-main-side-panel (panelValueSelection)="changePanel($event)"></startup-admin-main-side-panel>
            <!-- <startup-admin-master-side-panel *ngIf="panelName === 'master'" (panelValueSelection)="changePanel($event)"></startup-admin-master-side-panel> -->
        </div>
        <div class="small-screen-sidepanel" [ngClass]="smExpand ? '.sm-expand' : 'sm-close'" *ngIf="panelName === 'main' && srcWidth < 475">
            <startup-admin-main-side-panel (panelValueSelection)="changePanel($event)"
            (masterPanelClose)="closeMasterPanel($event)"></startup-admin-main-side-panel>
        </div>
        <div class="side-panel" [ngClass]="expand ? 'expand' : 'close'" *ngIf="panelName === 'master' && srcWidth > 475">
            <startup-admin-master-side-panel (panelValueSelection)="changePanel($event)"></startup-admin-master-side-panel>
        </div>
        <div class="small-screen-sidepanel" [ngClass]="smExpand ? '.sm-expand' : 'sm-close'" *ngIf="panelName === 'master' && srcWidth < 475">
            <startup-admin-master-side-panel (panelValueSelection)="changePanel($event)"
            (masterPanelClose)="closeMasterPanel($event)"></startup-admin-master-side-panel>
        </div>
        <div class="side-panel" [ngClass]="expand ? 'expand' : 'close'" *ngIf="panelName === 'webservices' && srcWidth > 475">
            <startup-admin-web-service-panel (panelValueSelection)="changePanel($event)"></startup-admin-web-service-panel>
        </div>
        <div class="small-screen-sidepanel" [ngClass]="smExpand ? '.sm-expand' : 'sm-close'" *ngIf="panelName === 'webservices' && srcWidth < 475">
            <startup-admin-web-service-panel (panelValueSelection)="changePanel($event)"
            (masterPanelClose)="closeMasterPanel($event)"></startup-admin-web-service-panel>
        </div>
        <div class="component-section">
            <nav class="navbar navbar-expand-lg navbar-light main-section">
                <div class="expand-img" *ngIf="srcWidth > 475">
                    <img src="./../../../assets/images/top-navbar/main-toggle.svg" alt="">
                </div>
                <div class="sm-expand-img" *ngIf="srcWidth < 475" (click)="sidepanelToggle()">
                    <img src="./../../../assets/images/top-navbar/main-toggle.svg" alt="">
                </div>
                <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown"> -->
                <div class="navbar-section">
                  <div class="navbar-nav">
                    <div class="globe-icon">
                        <img src="./../../../assets/images/side-panels/globe.svg" alt="" (click)="openWebServicePanel()">
                    </div>
                    <div class="masters-icon cursor-pointer" (click)="openMasterPanel()" *ngIf="staffRole === 'SUPER_ADMIN' || staffRole === 'ADMIN'">
                        <mat-icon>settings</mat-icon>
                    </div>
                    <div class="profile-section">
                        <div class="prof-img">
                            <!-- {{userName.split("")[0] | uppercase}} -->
                            <img src="./../../../assets/images/top-navbar/avatar.svg" alt="">
                        </div>
                        <div class="prof-title cursor-pointer" (click)="openDropDown($event,0)" id="clicked">
                            <!-- {{userName | titlecase}} -->
                            {{userName.split("")[0] | uppercase}}{{userName.split("")[1] | uppercase}} <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                        <div>
                            <div class="details-blog" *ngIf="detailBlog" [style.top.px]="top+52">
                                <div class="img-name">
                                    <div class="inside-img">
                                        <img src="./../../../assets/images/top-navbar/avatar.svg" alt="">
                                    </div>
                                    <div class="inside-title">
                                        <div>{{userName | titlecase}}</div>
                                        <div class="inside-mail">{{userEmail}}</div>
                                    </div>
                                </div>
                                <div class="logout cursor-pointer" (click)="logout()">
                                    <span class="material-icons">logout</span> &nbsp;&nbsp; Logout
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </nav>
            <div class="other-admin-sections">
                <div class="main-screen" *ngIf="srcWidth > 475">
                    <ng-container>
                        <router-outlet></router-outlet>
                    </ng-container>
                </div>
                <div class="sm-main-screen" *ngIf="srcWidth < 475">
                    <ng-container>
                        <router-outlet></router-outlet>
                    </ng-container>
                </div>
            </div>            
        </div>
    </div>
</section>