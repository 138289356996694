import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { AuthenticationComponent } from './all-components/authentication/authentication.component';
import { AdminConsoleComponent } from './all-components/admin-console/admin-console.component';
import { MainSidePanelComponent } from './all-components/shared-components/main-side-panel/main-side-panel.component';
import { MasterSidePanelComponent } from './all-components/shared-components/master-side-panel/master-side-panel.component';
import { LoaderComponent } from './all-components/shared-components/loader/loader.component';

import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { PasswordResetComponent } from './all-components/shared-components/password-reset/password-reset.component';
import { GlobalInterceptor } from './all-components/global-service-files/global.interceptor';
import { WebServicePanelComponent } from './all-components/shared-components/web-service-panel/web-service-panel.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    AdminConsoleComponent,
    MainSidePanelComponent,
    MasterSidePanelComponent,
    LoaderComponent,
    PasswordResetComponent,
    WebServicePanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition : true
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
