import { Component } from '@angular/core';
import { NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

@Component({
  selector: 'startup-admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'startup-admin';
  loadingRouteConfig : boolean;
  constructor(
    private router: Router
    ){}
  ngOnInit(): void {
    this.loaderForMainModules();
  }

  loaderForMainModules() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
  }
}
