import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar
  ) { }
  private _lsService = new SecureLS({encodingType:'rc4', isCompression: false});

  setUserName(body:any){
    this._lsService.set('873766',body);
  }
  getUserName(){
    return this._lsService.get('873766');
  }

  setUserId(body:any){
    this._lsService.set('873743',body)
  }
  getUserId(){
    return this._lsService.get('873743')
  }
  setPrevUrl(body:any){
    this._lsService.set('7738875',body);
  }
  getPrevUrl(){
    return this._lsService.get('7738875');
  }
  setToken(body:any){
    this._lsService.set('86536',body);
  }
  getToken(){
    return this._lsService.get('86536')
  }
  setStaffMail(body:any){
    this._lsService.set('6245',body);
  }
  getStaffMail(){
    return this._lsService.get('6245')
  }
  setStaffRole(body){
    this._lsService.set('7837653',body);
  }
  getStaffRole(){
    return this._lsService.get('7837653')
  }
  setPrevClick(body){
    this._lsService.set('prevClick',body);
  }
  getPrevClick(){
    return this._lsService.get('prevClick')
  }
  setNxtClick(body){
    this._lsService.set('nxtClick',body);
  }
  getNxtClick(){
    return this._lsService.get('nxtClick');
  }

  removeAll(){
    this._lsService.removeAll();
  }
}
