import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../../global-service-files/auth.service';
import { DbService } from '../../global-service-files/db.service';

@Component({
  selector: 'startup-admin-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss','./mq-password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  mailId = '';
  oldPassword ='';
  newPassword = '';
  hide : boolean = true;
  hide1 : boolean = true;
  addLoader : boolean = false;
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  constructor(
    public dialogRef: MatDialogRef<PasswordResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackbar : MatSnackBar,
    private _authService : AuthService,
    private _dbService : DbService,
    private _router : Router
  ) { }

  ngOnInit(): void {
    this.mailId = this.data.item.userName
  }

  enterOldPassword: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.min(3) ])
  });
  get oldPasswordInput() { return this.enterOldPassword.get('oldPassword'); }

  enterNewPassword: FormGroup = new FormGroup({
    newPassword: new FormControl('',[Validators.required, Validators.min(3)])
  });
  get newPasswordInput() { return this.enterNewPassword.get('newPassword')}

  close(){
    this.dialogRef.close({data:null})
  }


  updatePassword(){
    let data = {
      newPassword:this.newPassword,
      oldPassword:this.oldPassword,
      userName:this.mailId
    }
    this.addLoader = true;
    this._authService.resetPassword(data).subscribe(
      (res:any) => {
        let loginData= {
          userName : this.mailId,
          password : this.newPassword
        }
        this._authService.loginStaff(loginData).subscribe(
          (res:any) => {
              this.dialogRef.close();
              this._dbService.setToken(res.data.token);
              this._dbService.setUserId(res.data.staff.staffId);
              this._dbService.setUserName(res.data.staff.staffBasicInfo.staffName)
              this._dbService.setStaffMail(res.data.staff.staffBasicInfo.email);
              this._dbService.setStaffRole(res.data.staff.staffRole);
              this._router.navigate([`/startup/admin-portal/main/talenthire/list-talenthire`],{queryParams:{pageNo:1}});
              this.addLoader = false;
          },
          (err) => {
            this.addLoader = false;
            this._snackbar.open(
              `${err.error.message}`,
              'Close',
              {
                duration:1500
              }
            )
          }
        );
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.addLoader = false;
      }
    )
  }

}
