<div class="cross-btn" *ngIf="srcWidth < 475">
    <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<section>
    <div class="open">
        <div class="title-image">
            <img src="./../../../../assets/images/side-panels/branding-logo.svg" alt="brand-logo">
        </div>
        <!-- <div class="close-logo" *ngIf="!expantion">
            <img src="./../../../../assets/images/main-panel/closed_logo.svg" alt="logo">
        </div> -->
        <div class="main-panel">
            <div class="sub-panel">
                <div class="panel-section cursor-pointer" *ngFor="let item of mainPanel" (click)="openList(item, item?.status)"
                [ngClass]="item?.status ? 'panel-section-active':'panel-section'">
                    <div class="panel-elements">
                        <img src="./../../../../assets/images/side-panels/{{item.panelUrlCheck}}-{{item.routingUrl}}.svg" [alt]="item.routingUrl" *ngIf="!item.status">
                        <img src="./../../../../assets/images/side-panels/{{item.panelUrlCheck}}-{{item.routingUrl}}_active.svg" [alt]="item.routingUrl" *ngIf="item.status">
                        <div class="main-heading" [ngClass]="item?.status ? 'active-main-heading':'main-heading'">{{item?.name.split("_").join(" ") | titlecase}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>