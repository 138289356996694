<div class="cross-btn" *ngIf="srcWidth < 475">
    <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<section>
    <div class="open">
        <div class="title-image">
            <img src="./../../../../assets/images/side-panels/branding-logo.svg" alt="logo" (click)="gotoHome()" class="cursor-pointer">
        </div>
        <!-- <div class="close-logo" *ngIf="!expantion">
            <img src="./../../../../assets/images/main-panel/closed_logo.svg" alt="logo">
        </div> -->
        <div class="main-panel">
            <div class="sub-panel">
                <div class="go-back main-heading" (click)="goBack()">
                    <mat-icon>keyboard_arrow_left</mat-icon> &nbsp;Back To Home
                </div>
                <div class="panel-section cursor-pointer" *ngFor="let item of mainPanel; let i = index">
                    <div class="dropdown-heading" (click)="openList(item, item?.status)">
                        <div class="dropdown-text">
                            <img src="./../../../../assets/images/side-panels/master-{{item.name}}.svg" alt="">
                            <div class="main-heading" [ngClass]="item?.status ? 'active-main-heading':'main-heading'">{{item?.name.split("-").join(" ") | titlecase}}</div>
                        </div>
                        <div [ngClass]="item?.status ? 'dropdown-arrow' : 'active-dropdown-arrow'" *ngIf="item?.arrowVisibility">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                    <ng-container *ngIf="item?.subList && item?.status">
                        <div *ngFor="let subList of item?.subList; let j = index" 
                        [ngClass]="subList?.status ? 'active-sublist' : 'sub-list-section'"
                        (click)="subListSelection(subList,subList?.status,i,j)">
                            <ul class="unordered-list">
                                <li>{{subList?.name.split("-").join(" ") | titlecase}}</li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>