<section>
    <div class="heading-cross">
        <div class="sec-heading">Reset Password</div>
        <div class="cross-icon">
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="data-filling-section">
        <div class="half-field">
            <div class="label">Mail Id<sup>*</sup></div>
            <mat-form-field class="sfull-width" appearance="outline">
                <input matInput placeholder="Enter Mail Id" [(ngModel)]="mailId"/>
            </mat-form-field>
        </div>
        <div class="half-field">
            <div class="label">Old Password<sup>*</sup></div>
            <mat-form-field class="sfull-width" appearance="outline">
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter your password" [(ngModel)]="oldPassword"/>
                <mat-icon matSuffix (click)="hide = !hide" class="eye-icon cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="half-field">
            <div class="label">New Password<sup>*</sup></div>
            <mat-form-field class="sfull-width" appearance="outline">
                <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="Enter your password" [(ngModel)]="newPassword"/>
                <mat-icon matSuffix (click)="hide1 = !hide1" class="eye-icon cursor-pointer">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="btn-section">
        <div class="add-btn">
            <button (click)="updatePassword()">
                <span *ngIf="!addLoader">Update Password</span>
                <span *ngIf="addLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
            </button>
        </div>
    </div>
</section>