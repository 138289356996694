<!-- <div class="ring">
    <img src="./../../../../assets/images/common/for-loader.svg" alt="">
</div> -->

<!-- <div id="loader-wrapper">
    <div class="main-img">
        <img src="./../../../../assets/images/common/for-loader.svg" alt="">
    </div>
    <div id="loader">
    </div>
  </div> -->
  
  <div id="divSpinner" class="spinner loading">
    <img src="./../../../../assets/images/common/for-loader.svg" alt="">
 </div>