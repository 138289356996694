import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../../global-service-files/db.service';

@Component({
  selector: 'startup-admin-main-side-panel',
  templateUrl: './main-side-panel.component.html',
  styleUrls: ['./main-side-panel.component.scss']
})
export class MainSidePanelComponent implements OnInit {

  @Output() panelValueSelection = new EventEmitter<any>();
  @Output() masterPanelClose = new EventEmitter<any>();
  srcWidth : number;
  srcHeight : number;

  mainPanel: any =[
    {
      name:'talent hire',
      status:false,
      panelUrlCheck:'main',
      routingUrl:'talenthire'
    },
    {
      name:'acquiHire',
      status:false,
      panelUrlCheck:'main',
      routingUrl:'acquihire'
    },
    {
      name:'staff',
      status:false,
      panelUrlCheck:'main',
      routingUrl:'staff'
    },
    // {
    //   name:'contact_us',
    //   status:false,
    //   panelUrlCheck:'main',
    //   routingUrl:'contactus'
    // }
  ];

  constructor(
    private _router : Router,
    private _dbService : DbService
  ) {  
    this.getScreenSize();
    this.checkForRoles();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    let Url = this._router.url;
    let parts = Url.split('/');
    this.mainPanel.forEach(element => {
      if(element.routingUrl === parts[4]){
        element.status = true;
      }
    });
  }
  checkForRoles(){
    if(this._dbService.getStaffRole() === 'STAFF'){
      this.mainPanel.forEach((element,i) => {
        if(element.name === 'staff'){
          this.mainPanel.splice(i,1)
        }
      });
    }
    // else if(){
    //   this.mainPanel.forEach((element,i) => {
    //     if(element.name === 'staff')
    //   });
    // }
  }

  openList(item,status){
    this.panelValueSelection.emit({value:'main'})
    this.mainPanel.forEach(element => {
      if(element?.name.split('_').join('') === item?.name.split('_').join('')){
        element.status = true;
        this._router.navigate([`/startup/admin-portal/main/${element.routingUrl}/list-${element.routingUrl}`],{queryParams:{pageNo:1}});
      }
      else{
        element.status = false
      }
    });
  }
  logout(){
    // this._dbService.removeAll();
    // localStorage.clear();
    // this._router.navigate([''])
    window.location.reload();
  }
  close(){
    this.masterPanelClose.emit(false)
  }
}
