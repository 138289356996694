import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { DbService } from '../global-service-files/db.service';

@Component({
  selector: 'startup-admin-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.scss','./mq-admin-console.component.scss']
})
export class AdminConsoleComponent implements OnInit {

  loadingRouteConfig : boolean;
  panelName  = '';
  userName = '';
  userEmail;
  detailBlog : boolean = false;
  top : number;
  actionIconClicked : boolean = false;

  srcHeight;
  srcWidth ;
  expand : boolean = true;
  smExpand : boolean = true;
  previousSearch : boolean = false;
  subPanelWidth;
  filterPanelData;
  sidepanelValue ;
  mainPanelWidth ;
  twoPanelsWidth;
  subPaneltopPosition;
  staffRole = '';

  constructor(
    private router : Router,
    private cd : ChangeDetectorRef,
    private _snackMessage : MatSnackBar,
    private _dbService : DbService,
    private _router : Router
  ) { 

    this.getScreenSize();
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }
  // ngAfterViewInit(): void {
  //   // throw new Error('Method not implemented.');
  //   if(this.srcWidth > 475){
  //     this.mainPanelWidth = this.flexWidth.nativeElement.offsetWidth
  //     // console.log(this.mainPanelWidth);
  //     this.cd.detectChanges();
  //   }
  // }

  ngOnInit(): void {
    let panelUrlCheck= this.router.url.split('/')[3]; 
    if(panelUrlCheck === 'main'){
      this.panelName = 'main'
    }
    else if(panelUrlCheck === 'webservices'){
      this.panelName = 'webservices'
    }
    else{
      this.panelName = 'master'
    }
    
    this.getUserDetails();
    this.userName = this._dbService.getUserName();
    this.userEmail = this._dbService.getStaffMail();
    this.staffRole = this._dbService.getStaffRole();
    
  }
  getUserDetails(){
    // this._commonService.getUserDetails(this._dbService.getUserId()).subscribe(
    //   (res:any) => {
    //     // console.log(res.data);
    //     this.userName = res.data.fullName;
    //   },
    //   (err) => {
    //     // console.log(err);
    //     this._snackMessage.open(`${err.error.message}`,'Close',{
    //       duration:1500
    //     })
    //   },
    //   () => {

    //   }
    // )
  }

  loaderForMainModules() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
  }
  
  // checks: boolean = false;
  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   this.actionIconClicked = true;
  //   if (!event.target.className.includes('show-hide')) {
  //     this.detailBlog = false;
  //   }
  // }
  

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  sidepanelToggle(){
    this.smExpand = true;
  }
  closeMasterPanel(ev){
    this.smExpand = false;
  }
  changePanel(ev){
    this.panelName = ev.value;
  }
  openMasterPanel(){
    // if(this._dbService.getPrevUrl() === ''){
      // console.log(window.location.href);
      
      this._dbService.setPrevUrl(window.location.href)
      this.router.navigate([`/startup/admin-portal/master/location/list-countries`]);
      this.panelName = "master";
    // }
    // else{
    //   let pathName = new URL(this._dbService.getPrevUrl()).pathname;
    //   this._router.navigate([`${pathName}`]);
    //   // this.router.navigate([`/startup/admin-portal/master/location/list-states`]);
    //   this.panelName = "master";

    //   this._dbService.setPrevUrl(window.location.href)
    // }
  }
  openWebServicePanel(){
    this._dbService.setPrevUrl(window.location.href);
    this.router.navigate([`/startup/admin-portal/webservices/contactus/list-contactus`]);
    this.panelName = "webservices";
  }
  openDropDown(ev,i){
      this.detailBlog = !this.detailBlog;
      if(this.detailBlog){
        this.top = ev.target.getBoundingClientRect().top
      }
      else{}
  }
  logout(){
    this._dbService.removeAll();
    window.location.reload();
  }
}
