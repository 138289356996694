import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../global-service-files/auth.service';
import { DbService } from '../global-service-files/db.service';
import { PasswordResetComponent } from '../shared-components/password-reset/password-reset.component';

@Component({
  selector: 'startup-admin-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss','./mq-authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  hide : boolean = true;
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  signInLoader : boolean = false;
  email = '';
  password = '';

  constructor(
    private _router : Router,
    private _globalService : AuthService,
    private _snackBar : MatSnackBar,
    private _dbService : DbService
  ) { }

  ngOnInit(): void {
    if(this._dbService.getUserId() === null){}
    else{
      this._router.navigate(['/startup/admin-portal/main/talenthire/list-talenthire'],{queryParams:{pageNo:1}});
    }
  }

  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3) ])
  });
  get passwordInput() { return this.signin.get('password'); }

  login(){
    if(this.emailRegex.test(this.email)){
      let data = {
        userName : this.email,
        password : this.password
      }
      this.signInLoader = true;
      this._globalService.loginStaff(data).subscribe(
        (res:any) => {
          // console.log(res.data);
          if(res.data.staff.resetPassword){
            this._globalService.openPopup(
              PasswordResetComponent,
              'auto',
              '450px',
              {item:data}
            )
          }
          else{
            this._dbService.setToken(res.data.token);
            this._dbService.setUserId(res.data.staff.staffId);
            this._dbService.setUserName(res.data.staff.staffBasicInfo.staffName)
            this._dbService.setStaffMail(res.data.staff.staffBasicInfo.email);
            this._dbService.setStaffRole(res.data.staff.staffRole);
            // console.log(this._dbService.getStaffMail());
            this._router.navigate([`/startup/admin-portal/main/talenthire/list-talenthire`],{queryParams:{pageNo:1}});
          }
          // this._router.navigate(['/startup/admin-portal/master/location/list-countries'])
          this.signInLoader = false;
        },
        (err) => {
          // console.log(err);
          this.signInLoader = false;
          this._snackBar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
        }
      )
    }
    else{
      this._snackBar.open(
        'Please Provide valid mailId',
        'Ok',
        {
          duration:1500
        }
      )
    }
    // this._router.navigate([`/startup/admin-portal/main/talenthire/list-talenthire`])
  }

}
